import { log, api } from ":mods";
import { ENDPOINTS } from "../const";

export async function getTokenDetails() {
  return api
    .getAuth(ENDPOINTS.get_token_details)
    .then(async (res) => {
      // console.log("login res :: ", res);
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      log.dev.info("get_token_details error :: ", err);
      return Promise.reject(err);
    });
}
